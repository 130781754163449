import React from 'react';
import Button from '../../../../components/common/Button';
import PageHeader from '../../../../components/layout/PageHeader';
import Wrapper from '../../../../components/layout/Wrapper';
import { OfferTotals } from '../../../../gql/generated';
import OfferCountPanels from '../OfferCountPanels';
import styles from './styles.module.scss';


interface WelcomeComponentProps {
  name?: string;
  offerTotals?: OfferTotals;
}

const WelcomeComponent = ({ name, offerTotals }: WelcomeComponentProps) => {
  const totalOffers = offerTotals?.totalOffers || 0;
  const regionalOffers = offerTotals?.regionalOffers || 0;
  const nationalOffers = offerTotals?.nationalOffers || 0;
  const dealerOffers = offerTotals?.dealerOffers || 0;
  const expiredOffers = offerTotals?.expiredOffers || 0;

  return (
    <div>
      <Wrapper>
        <ul className={styles.bannerMessage}>
          <li>The current TOOMs system is undergoing construction and will transition over to the AMP platform. Any offers created will not appear on websites until the new system is up early in 2025.</li>
          <li>Future communications will be sent informing Regional and Dealer users of the official launch of the new TOOMs and educational webinars will be made available as well.</li> 
          <li>Please contact your Region team if you have any questions.</li>
        </ul>
        <PageHeader>
          <h2>{name && <>Welcome, {name}</>}</h2>
          <div>
            <Button id="create-offer-btn" variant="primary" element="navlink" to="/createOffer">
              Create Offer
            </Button>
          </div>
        </PageHeader>
        <OfferCountPanels totalOffers={totalOffers} regionalOffers={regionalOffers} nationalOffers={nationalOffers} dealerOffers={dealerOffers} expiredOffers={expiredOffers} />
      </Wrapper>
    </div>
  );
};

export default WelcomeComponent;
